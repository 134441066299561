.testimonial-carousel {
    text-align: center;
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.testimonial-content {
    width: 80%;
    max-width: 500px;
    margin: auto;
    color: white;
}

.custom-stars .rs-icon-star {
    color: #FFA500 !important;
}


/* Hide the carousel indicators */

.carousel-indicators {
    display: none !important;
}