.background_news {
    background-image: url(images/news_background.jpg);
    background-size: 100%;
    padding-bottom: 350px;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center-text_news {
    position: absolute;
    top: 37%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.background-text_news {
    color: white;
    font-size: 4em;
    font-weight: 900;
    font-family: Maven Pro;
    padding-top: 1.3em;
}

.news-div {
    padding: 8em 5em;
    font-family: Maven Pro;
}

@media only screen and (max-width: 900px) {
    .background-text_news {
        font-size: 2.7em;
    }
    .center-text_news {
        top: 27%;
    }
    .news-div {
        padding: 8em 0;
    }
}