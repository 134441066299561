.background_contact {
    background-image: url(images/customer-background.jpg);
    background-size: 100%;
    padding-bottom: 350px;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center-text_contact {
    position: absolute;
    top: 37%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.background-text_contact {
    color: white;
    font-size: 4em;
    font-weight: 900;
    font-family: Maven Pro;
    padding-top: 1.3em;
}

.contact-min-div {
    padding: 6em 4em 2em;
    width: 70%;
    margin: auto
}

.contact-title {
    font-weight: 800;
    font-family: Maven Pro;
    text-align: center;
    margin-bottom: 1em;
}

.contact-location-div {
    background-color: #023020;
    display: flex;
    padding: 6em 4em;
    margin: 2em 0
}

.contact-location-title-div {
    width: 50%;
}

.contact-location-add-div {
    width: 50%;
    padding: 3em 3em 3em 6em;
    transform: translate(0, 20%);
    text-align: center;
    font-family: Maven Pro;
}

.contact-location-add {
    color: white;
    font-size: 1.3em;
    font-weight: 700
}

.contact-location-text {
    color: white;
    font-size: 3em;
    font-family: Maven Pro;
}

.nav-tabs .nav-link {
    background-color: #fff;
    color: #023020;
    font-weight: bold;
    border: 1px solid rgb(212, 202, 202);
    border-radius: 3px;
}

.nav-tabs .nav-link.active {
    background-color: #023020;
    color: #ffffff;
}

@media only screen and (max-width: 900px) {
    .background-text_contact {
        font-size: 3.5em;
    }
    .contact-min-div {
        width: 100%;
    }
    .contact-location-div {
        display: grid
    }
    .contact-location-title-div {
        width: 100%;
    }
    .contact-location-add-div {
        width: 100%;
        padding: 1.3em;
    }
    .contact-location-text {
        font-size: 1.8em;
        text-align: center;
    }
    .contact-location-add {
        font-size: 1.1em;
        font-weight: 600
    }
    .center-text_contact {
        top: 27%;
    }
}