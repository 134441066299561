.background_career {
    background-image: url(images/career-background.jpg);
    background-size: 100%;
    padding-bottom: 450px;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center-text_career {
    position: absolute;
    top: 45%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.background-text_career {
    color: white;
    font-size: 4em;
    font-weight: 900;
    font-family: Maven Pro;
    padding-top: 1.3em;
}

.career-title {
    font-weight: 800;
    font-family: Maven Pro;
}

.job-list {
    list-style-type: square;
}

.job-heading {
    font-weight: 700;
    font-family: Maven Pro;
}

.career-head-div {
    padding: 5em 4.5em 2em;
    text-align: center;
    width: 70%;
    margin: auto
}

.career-div {
    padding: 2em 5em;
    width: 70%;
    margin: auto;
    font-family: Maven Pro;
}

@media only screen and (max-width: 900px) {
    .career-head-div,
    .career-div {
        width: 100%;
        text-align: start;
        padding: 2em;
    }
    .background-text_career {
        font-size: 4em;
    }
    .center-text_career {
        top: 35%;
    }
}