.background {
    background-image: url(images/about-background.jpg);
    background-size: 100%;
    padding-bottom: 560px;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center-text {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 10px;
    width: 100%;
    text-align: center;
}

.background-text {
    color: white;
    font-size: 7em;
    font-weight: 900;
    font-family: Maven Pro;
    padding-top: 1.3em;
}

.number-start {
    border-radius: 50%;
    background-color: green;
    padding: 0.4em 0.8em;
    color: white;
    font-weight: 800
}

.title-start {
    padding: 0.4em 0;
    font-weight: 700;
    font-family: Maven Pro;
    margin-top: 0.6em;
}

.description-start {
    font-family: Maven Pro;
}

.accordation-heading {
    font-weight: 700;
    font-size: 1.15em;
    margin-bottom: 0;
}

.accordation-text {
    font-family: Maven Pro;
    opacity: 0.8;
    margin: 0;
}

.our-mission-div {
    display: flex;
    padding: 3em 4.5em
}

.our-mission-image {
    width: 40%
}

.mission-image {
    height: 20em;
    width: auto
}

.mission-accordation {
    width: 50%;
    margin-left: 6em;
    font-family: Maven Pro;
}

.about-heading {
    text-align: center;
    font-size: 3em;
    font-family: Maven Pro;
    font-weight: 700;
}

.main-about-div-content {
    margin: 5em 3em 0
}

.left-about-us {
    width: 70%;
    padding: 2em 0
}

.empty-right-about {
    width: 30%
}

.right-about-us {
    width: 70%;
    padding: 2em 0;
    text-align: end
}

@media only screen and (max-width: 900px) {
    .left-about-us {
        width: 100%;
    }
    .empty-right-about {
        width: 0%;
    }
    .right-about-us {
        width: 100%;
        text-align: left;
    }
    .our-mission-div {
        display: inline;
        justify-content: center;
    }
    .our-mission-image {
        width: 80%;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .mission-image {
        height: 13em;
    }
    .mission-accordation {
        width: 80%;
        margin: auto;
        padding: 3em 0;
    }
    .background-text {
        font-size: 5em;
    }
    .center-text {
        top: 35%;
    }
}