.custom-navbar {
    background-color: #ffffff;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    font-family: Maven Pro;
}

.logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.link-logo {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.navbar-nav .nav-link {
    margin-right: 20px;
}

.custom-navbar .nav-button {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 5px;
}

.custom-navbar .login-button {
    margin-right: 10px;
}

.custom-navbar .cart-button {
    background-color: #28a745;
    color: white;
}

.custom-navbar .nav-link {
    font-size: 1em;
    color: #333;
    transition: color 0.3s ease;
    font-weight: 600;
}

.custom-navbar .nav-link:hover {
    color: #278410;
    text-decoration: none;
    font-weight: 800;
}

.ml-auto {
    padding: 0em 2em;
}