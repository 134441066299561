.social-media-links {
    display: flex;
    justify-content: center;
    gap: 14px;
    font-size: 20px;
    margin: 10px;
    padding-right: 1em
}

.social-link-content {
    color: #06717E;
    opacity: 0.8
}

.social-link-content:hover {
    color: #fff;
    opacity: 1;
}

.social-media-link {
    display: flex;
    gap: 14px;
    font-size: 24px;
    justify-content: center;
}

.social-media-links a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.social-media-links a:hover {
    color: #0056b3;
}

.footer-link-content {
    color: #06717E;
    text-decoration: none;
    margin: 1em 0;
}

.footer-link-content:hover {
    font-weight: 700;
}

.logo-footer-company {
    width: 15em;
    padding-bottom: 0.5em;
}

.footer-main-tagline {
    padding-right: 1.3em;
    color: #06717E;
}

.footer-copy {
    color: #06717E;
    opacity: 0.85;
    text-align: center
}

.footer-copy-1 {
    color: #06717E;
    opacity: 0.85;
    text-align: center;
    margin: -1.25em 0 0;
    font-size: 0.75em
}

.footer-copy-2 {
    color: #06717E;
    opacity: 0.85;
    text-align: center;
    margin: -0.2em 0 0;
    font-size: 0.75em
}

@media (max-width: 1000px) {
    .footer-copy {
        font-size: 0.7em;
    }
    .footer-copy-1,
    .footer-copy-2 {
        font-size: 0.6em;
    }
    .footer-copy-1 {
        margin: -1.6em 0 0;
    }
    .logo-footer-company {
        width: 80%;
        display: flex;
        margin: 0 auto;
    }
    .footer-main-tagline {
        text-align: left;
        padding-right: 1.8em;
        margin: 0.4em 0 3em;
        width: 100%;
    }
    .social-media-links {
        padding-right: 0;
        margin-bottom: 2em;
    }
    .social-media-link {
        justify-content: left;
    }
}