.background_rd {
    background-image: url(images/r&d-background.jpg);
    background-size: 100%;
    padding-bottom: 350px;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center-text_rd {
    position: absolute;
    top: 37%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.background-text_rd {
    color: white;
    font-size: 4em;
    font-weight: 900;
    font-family: Maven Pro;
    padding-top: 1.3em;
}

.rd-title {
    font-weight: 800;
    font-family: Maven Pro;
    text-align: center;
}

.rd-div {
    padding: 6em 4em;
    width: 70%;
    margin: auto;
    font-family: Maven Pro;
}

@media only screen and (max-width: 900px) {
    .background-text_rd {
        font-size: 2.8em;
    }
    .rd-div {
        width: 100%;
        padding: 6em 3em;
    }
    .center-text_rd {
        top: 27%;
    }
}