.background-image {
    object-fit: cover;
    width: 100%;
    height: 37em;
}

.center-text-home {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 10px 12em;
    width: 100%;
    text-align: center;
}

.background-text-home {
    color: white;
    font-size: 3em;
    font-weight: 900;
    font-family: Maven Pro;
    padding-top: 1.3em;
}

.our-story-main-div {
    margin: 8em 9em 0
}

.our-story-container {
    display: flex;
    margin: 4.5em 0;
    justify-content: space-between
}

.our-story-image-left {
    width: '90%';
}

.our-story-head-image {
    height: 38em;
    width: auto
}

.our-story-container1 {
    width: 50%;
    margin-left: 3em;
}

.testimonial-div {
    background-color: #023020;
    padding: 0.1em 0 2em
}

.home-page-title {
    text-align: center;
    font-weight: 700;
    font-family: Maven Pro;
    font-size: 2.6em;
    color: black
}

.home-rd-column {
    width: 70%;
    margin: auto;
    padding: 3em;
    font-family: Maven Pro;
}

.background-containers {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    text-align: center;
    overflow: hidden;
}

.button-30 {
    align-items: center;
    appearance: none;
    background-color: #FFFAE4;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.button-30:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.button-30:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

.project-div-rd {
    margin: 2em 1em 0
}

@media only screen and (max-width: 900px) {
    .project-div-rd {
        margin: 2em 0 0;
    }
    .home-rd-column {
        width: 95%;
    }
    .home-page-title {
        font-size: 2em;
    }
    .our-story-main-div {
        margin: 8em auto;
    }
    .ourStoryPic {
        max-height: 50%;
    }
    .our-story-image-left {
        width: 100%;
    }
    .our-story-head-image {
        height: 19em;
        display: flex;
        justify-content: center;
        margin: 3em auto;
    }
    .our-story-container {
        display: inline;
        justify-content: center;
    }
    .our-story-container1 {
        width: 80%;
        margin: 4em auto;
        justify-content: center;
    }
    .background-text-home {
        font-size: 2.3em;
    }
    .center-text-home {
        top: 50%;
        padding: 10px 4em;
    }
}